<template>
    <div>
        <br>
        <div align="center">
            <span class="text-h4">Elenco dei pagamenti</span>
        </div>
        <br><br>

        <q-table
            title="Elenco dei pagamenti fatti dal Contraente"
            :data="rows"
            :columns="colonne"
            :filter="filter"
            separator="cell"
            row-key="id"
            flat
            :visible-columns="visibleColumns"
            @row-click="selezionaRecordPagamenti"
            class="my-sticky-header-table no-box-shadow q-mt-none">

            <template v-slot:top-right>
                <div class="panel-cerca">
                    <q-input outlined dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </div>
            </template>

            <template v-slot:body="props">
                <q-tr :props="props" style="cursor: pointer;"
                    @click.native="selezionaRecordPagamenti(null,props.row)">
                    <q-td auto-width>
                        {{ props.cols[0].value }}
                    </q-td>
                    <q-td auto-width>
                        {{ props.cols[1].value }}
                    </q-td>
                    <q-td auto-width>
                        {{ props.cols[2].value }}
                    </q-td>
                    <q-td auto-width>
                        {{ props.cols[3].value }} Euro
                    </q-td>
                    <q-td auto-width align="center">
                        <q-btn color="red" label="Stampa ricevuta" />
                    </q-td>
                </q-tr>
            </template>

        </q-table>


        <br><br><hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />
            </div>
        </div>
        <br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import axios from 'axios';
    import { mapState, mapActions } from "vuex";

    export default {
        name: "ElencoPagamenti",
        data() {
            return {
                filter: '',
                annotazione: "",
                colonne: [],
                rows: [],
                visibleColumns: ['unita_operativa','create_at','modalita_incasso','premio','ricevuta']
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapState({
                formPratica: state => state.gestionePratiche.formPratica
            })
        },
        methods: {
            ...mapActions({
                fetchElencoPagamentiPratica: "gestionePratiche/fetchElencoPagamentiPratica",
                fetchUrlRicevutaPagamento: "gestioneDocumenti/fetchUrlRicevutaPagamento"
            }),
            onClickIndietro() {
                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
            },
            async selezionaRecordPagamenti(event,row) {
                var Numero = this.formPratica.preventivo.id;
                var _create = row.create_at.replaceAll("/","_");
                // Richiese url per il download
                var url = await this.fetchUrlRicevutaPagamento({NumeroPratica: Numero, create_at: _create});
                var filename = "ricevuta_"+_create;
                //console.log("filename:",filename,url);

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                })
                .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', filename+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });


               // window.location = url;

               // console.log(event,url);
            }
        },
        async mounted() {
            var NumeroPratica = this.formPratica.preventivo.id;
            var result = await this.fetchElencoPagamentiPratica(NumeroPratica);

            this.colonne = result.columns;
            this.rows    = result.rows;
        },
        async activated() {
            var NumeroPratica = this.formPratica.preventivo.id;
            var result = await this.fetchElencoPagamentiPratica(NumeroPratica);

            this.colonne = result.columns;
            this.rows    = result.rows;
        }
    }

</script>
